import { css } from "@emotion/css";
import { FC } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useState } from "@hookstate/core";

import { portalState } from "./state";
import { Dictionary } from "components/pdf";
import { dictionaryState } from "components/pdf/state";

const style = css`
  position: fixed;
  top: 0;
  left: 390px;
  bottom: 0;
  right: auto;
  width: 800px;
  background-color: whitesmoke;
  box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.75);
  overflow: scroll;
  z-index: 2;
`;

export const Portal: FC = () => {
  const state = useState(portalState);
  const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: -100 },
  };

  return (
    <AnimatePresence>
      {state.get().open && (
        <motion.div
          className={style}
          initial={{ opacity: 1, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          variants={variants}
          exit={"closed"}
          layout
        >
          <RenderChild page={state.get().child} />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

interface RenderChildProps {
  page: null | string;
}

const RenderChild: FC<RenderChildProps> = ({ page }) => {
  const state = useState(dictionaryState);

  switch (page) {
    case "pdf":
      return <Dictionary root={state.get().root} />;
    default:
      return <p>search</p>;
  }
};
