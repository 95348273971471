import { FC } from "react";
import { css } from "@emotion/css";

const style = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 5em;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  background-color: khaki;
  overflow: scroll;

  z-index: 1;
  a {
    margin: 6px;
  }
`;

export const Nav: FC<{}> = ({ children }) => {
  return <nav className={style}>{children}</nav>;
};
