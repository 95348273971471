import { css } from "@emotion/css";
import { useState } from "@hookstate/core";
import { useEffect } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { Link } from "react-router-dom";

import { getQuranIndex, indexState } from "./state";

const containerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: palegoldenrod;

  overflow: scroll;
`;

const surahStyle = css`
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  color: gainsboro;
  :hover {
    color: blanchedalmond;
  }
`;

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

function SurahListData() {
  const state = useState(indexState);

  useEffect(() => {
    state.set(getQuranIndex);
  }, []);

  if (state.promised) {
    return <span>loading ...</span>;
  }

  return (
    <div className={containerStyle}>
      {state.get().map((v) => (
        <div className={surahStyle} key={v.name}>
          <Link key={v.surah_id} to={`surah/${v.surah_id}/${v.pages[0]}`}>
            <span>{v.surah_id}</span>
            <span> </span>
            <span>{v.name}</span>
          </Link>
        </div>
      ))}
    </div>
  );
}

export function SurahListView() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {}}>
      <SurahListData />
    </ErrorBoundary>
  );
}
