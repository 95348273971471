import { createState } from "@hookstate/core";

interface PortalState {
  open: boolean;
  view: string;
  child: null | string;
}

export const portalState = createState<PortalState>({
  open: false,
  view: "none",
  child: "",
});
