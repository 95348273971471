import { css } from "@emotion/css";
import { FC } from "react";
import { portalState } from "components/portal/state";
import { dictionaryState } from "components/pdf/state";
import { useState } from "@hookstate/core";

interface ActionBoxProps {
  root: string;
}

const actionBox = css`
  align-self: flex-start;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.4rem;
`;

export const ActionBox: FC<ActionBoxProps> = ({ root }) => {
  const dState = useState(dictionaryState);
  const pState = useState(portalState);

  const openDictionary = () => {
    dState.merge({ open: true, root: root, page: 83 });
    pState.merge({ open: true, child: "pdf" });
  };

  return (
    <ul className={actionBox}>
      <li>
        <span onClick={(e) => openDictionary()}>open dictionary</span>
      </li>
      <li>
        <span onClick={(e) => openDictionary()}>serach in verses</span>
      </li>
    </ul>
  );
};
