import React, { useEffect, FC } from "react";
import { dictionaryState } from "./state";
import { Document, Page, pdfjs } from "react-pdf";
import dictionary from "../../assets/pdf/dictionary.pdf";
import { portalState } from "components/portal/state";
import { pages } from "../../assets/json/dic";
import { useState } from "@hookstate/core";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface DictionaryProps {
  root: null | string;
}
export const Dictionary: FC<DictionaryProps> = ({ root }) => {
  const dState = useState(dictionaryState);
  const pState = useState(portalState);

  useEffect(() => {
    let p = 0;
    let r = dState.get().root;
    if (r != null) {
      for (let i = pages.length - 1; i >= 0; i--) {
        if (pages[i][0] <= r) {
          p = (pages[i][1] as number) + 26;
          break;
        }
      }
    }

    dState.page.set(p);
  }, [root]);

  const closeDictionary = (_: React.MouseEvent) => {
    // dic state
    dState.open.set(false);

    // portal state
    pState.open.set(false);
  };

  const goToPage = (page: number) => dState.page.set(page);

  function onDocumentLoadSuccess(pdf: pdfjs.PDFDocumentProxy) {
    dState.total.set(pdf.numPages);
  }

  function onLoadError(error: Error) {
    console.log(error.message);
    dState.total.set(0);
  }

  return (
    <div>
      <button onClick={(e) => closeDictionary(e)}> CLOSE Dictionary</button>
      <button onClick={(e) => goToPage(dState.get().page - 1)}> prev</button>
      <button onClick={(e) => goToPage(dState.get().page + 1)}> next</button>
      <span>
        {dState.get().page - 26} of {dState.get().total}
      </span>
      <Document
        file={dictionary}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onLoadError}
      >
        <Page pageNumber={dState.get().page} width={800} scale={1.0} />
      </Document>
    </div>
  );
};
