import { createState } from "@hookstate/core";

interface SiderState {
  open: boolean;
  view: null | string;
  child: null | string;
}
export const siderState = createState<SiderState>({
  open: true,
  view: "none",
  child: "",
});
