import { Surah, SurahC, toSurah } from "quran-types";
import { createState } from "@hookstate/core";
import { myfetcher } from "../../app-state";

export const indexState = createState<Surah[]>([]);

export const getQuranIndex = async (): Promise<Surah[]> => {
  const response = await myfetcher.get<SurahC[]>("list_surah?limit=114");
  return response.map((r) => toSurah(r));
};
