import { css } from "@emotion/css";
import { useState } from "@hookstate/core";
import { preferenceState } from "app-state";

const style = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: palegoldenrod;

  overflow: scroll;
`;

export const Settings = () => {
  const state = useState(preferenceState);

  const fontChange = (v: React.FormEvent<HTMLSelectElement>) => {
    const selected = v.currentTarget.value;
    state.merge({ font: selected });
  };

  const sizeChange = (action: "inc" | "dec") => {
    if (action === "inc") state.merge({ fontSize: state.get().fontSize + 0.1 });
    else state.merge({ fontSize: state.get().fontSize - 0.1 });
  };
  return (
    <div className={style}>
      <div>fonts face</div>
      <select onChange={fontChange}>
        <option value="amiri_quran">amiri quran</option>
        <option value="global_quran">global quran</option>
        <option value="me_quran">me quran</option>
        <option value="kfc">uthman taha naskh</option>
        <option value="scheherazade">scheherazade</option>
        <option value="al_quran_al_kareem">al quran al kareem</option>
      </select>

      <div>font size</div>
      <span>
        <button onClick={() => sizeChange("dec")}>-</button>
      </span>
      <span>{state.get().fontSize.toFixed(1)}</span>
      <button onClick={() => sizeChange("inc")}>+</button>
      <span></span>
    </div>
  );
};
