import { css } from "@emotion/css";
import { FC } from "react";
import { useState } from "@hookstate/core";

import { inspectorState } from "./state";
import { ActionBox } from "./actionbox";
import { Translations } from "./wordbox";

interface TitleProps {
  label: string;
}

interface RootProps {
  label: string;
}

const style = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: palegoldenrod;
  font-family: amiri;
  font-size: 2rem;
`;

const rootStyle = css`
  align-self: flex-start;
  margin-left: 1em;
  font-family: amiri;
  font-size: 1.4rem;
  span {
    text-decoration: underline;
  }
`;

export function Inspector() {
  const v = useState(inspectorState);

  return (
    <div className={style}>
      <Title label={v.get().word || ""} />
      <Root label={v.get().root || ""} />
      <Translations />
      <ActionBox root={v.get().root || ""} />
    </div>
  );
}

const Title: FC<TitleProps> = ({ label }) => {
  return <h3>{label}</h3>;
};

const Root: FC<RootProps> = ({ label }) => {
  return (
    <h4 className={rootStyle}>
      <span>root: </span> <span>{label}</span>
    </h4>
  );
};
