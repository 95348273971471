import { css } from "@emotion/css";
import { FC } from "react";
import { useState } from "@hookstate/core";
import { motion, AnimatePresence } from "framer-motion";

import { siderState } from "./state";
import { SurahListView } from "../../screens/surah-list";
import { Inspector } from "../../components/inspector";
import { Settings } from "screens/settings/view";

const siderStyle = css`
  height: 100%;
  width: 30em;
  z-index: 2;
  overflow: scroll;
  a {
    margin: 6px;
  }
`;

export const Sider: FC = () => {
  const v = useState(siderState);

  const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: -100 },
  };

  return (
    <AnimatePresence>
      {v.get().open && (
        <motion.div
          className={siderStyle}
          initial={{ opacity: 1, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          variants={variants}
          exit={"closed"}
          layout
        >
          <RenderChild page={v.get().child || ""} />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

interface RenderChildProps {
  page: string;
}

const RenderChild: FC<RenderChildProps> = ({ page }) => {
  switch (page) {
    case "wordInspector":
      return <Inspector />;
    case "surahList":
      return <SurahListView />;
    case "settings":
      return <Settings />;
    default:
      return <SurahListView />;
  }
};
