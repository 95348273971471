import { css } from "@emotion/css";
import { useEffect } from "react";
import { useState, none } from "@hookstate/core";
import { inspectorState, wordsState } from "./state";
import { myfetcher } from "app-state";

const wordBox = css`
  width: 100%;
  align-self: flex-start;
  margin-left: 1em;
  font-family: amiri;
  font-size: 1.4rem;
  border: 1px solid gray;
  span {
    text-decoration: underline;
  }
  input[type="text"] {
    border: 0;
    background-color: transparent;
    outline: none;
    :hover {
      border-bottom: 1px solid black;
    }
    :focus {
      border-bottom: 1px solid gray;
    }
  }
`;

const getWords = async (w: string) => {
  const url = `/word/${encodeURIComponent(w)}`;
  return await myfetcher.get<string[]>(url);
};

export const Translations = () => {
  const inspector = useState(inspectorState);
  const myWords = useState(wordsState);
  const current = inspector.get().word || "";

  useEffect(() => {
    myWords.set(getWords(current));
  }, [current]);

  const addNewWord = () => {
    myWords.merge([""]);
  };

  const deleteWord = (id: number) => {
    myWords[id].set(none);
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const v = e.currentTarget.value;
    const id = e.currentTarget.id.split("#")[1];
    myWords[parseInt(id)].set(v); // increments value of an element at 0 position
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const b = JSON.stringify({
      name: encodeURIComponent(inspector.get().word || ""),
      value: myWords.value,
    });
    // we need to clean get /word/{$word}
    const c = [`/word/${inspector.get().word || ""}`];

    await myfetcher.post("/word", b, c);
  };

  return (
    <div className={wordBox}>
      <div>Definition</div>
      <form onSubmit={handleSubmit}>
        <div>
          <span>
            <input type="submit" value="Submit" />
          </span>
          <span>
            <input type="button" value="add" onClick={() => addNewWord()} />
          </span>
        </div>
        {myWords.promised ? (
          <span>loading</span>
        ) : (
          myWords.get().map((w, i) => {
            return (
              <div key={`div_word_${i}`}>
                <span>
                  <input
                    type="text"
                    name={`words#${i}`}
                    id={`id#${i}`}
                    value={w}
                    placeholder="enter word"
                    onChange={handleChange}
                  />
                  <input
                    type="button"
                    onClick={() => deleteWord(i)}
                    value="x"
                  />
                </span>
              </div>
            );
          })
        )}
      </form>
    </div>
  );
};
