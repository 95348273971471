import { createState } from "@hookstate/core";

interface DictionaryState {
  open: boolean;
  page: number;
  total: number;
  root: null | string;
}

export const dictionaryState = createState<DictionaryState>({
  open: false,
  page: 1,
  total: 0,
  root: null,
});
