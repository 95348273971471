import { FC } from "react";
import { css } from "@emotion/css";
import { motion } from "framer-motion";

const style = css`
  height: 100%;
  width: 100%;
  direction: ltr;
  overflow: scroll;
`;

export const Container: FC<{}> = ({ children }) => {
  return (
    <motion.div layout className={style}>
      {children}
    </motion.div>
  );
};
