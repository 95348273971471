export const About = () => {
  return (
    <div>
      <p>
        the application main goal is to help non native arabic speaker
        understand the text of the holy Quran.
      </p>
    </div>
  );
};
