import { fetcher } from "fetcher";
import { createState } from "@hookstate/core";

export const myfetcher = new fetcher(
  process.env.REACT_APP_API_URL,
  "quran-reader"
);

interface Preference {
  font: string;
  fontSize: number;
}

const defaultPreference: Preference = {
  font: "scheherazade",
  fontSize: 3,
};

export const preferenceState = createState<Preference>(defaultPreference);
