import { useState } from "@hookstate/core";
import { getQuranIndex } from "screens/surah-list/state";
import { Link, useParams } from "react-router-dom";
import { css } from "@emotion/css";

const pageNavStyle = css`
  justify-self: center;
`;
export const Pagination = () => {
  const state = useState(getQuranIndex);
  const { page, surah } = useParams();
  if (state.promised) return <span>loading</span>;
  const pg = parseInt(page);
  const s = parseInt(surah);
  const e = state.get()[s - 1].pages;
  const firstPage = e[0];
  const lastPage = e[e.length - 1];

  return (
    <span className={pageNavStyle}>
      {firstPage !== pg ? (
        <Link to={`/surah/${surah}/${parseInt(page) - 1}`}>PREV PAGE</Link>
      ) : (
        <></>
      )}
      {lastPage !== pg ? (
        <Link to={`/surah/${surah}/${parseInt(page) + 1}`}>NEXT PAGE</Link>
      ) : (
        <></>
      )}
    </span>
  );
};
