import { createState } from "@hookstate/core";

interface InspectorState {
  open: boolean;
  word: null | string;
  root: null | string;
}

export const inspectorState = createState<InspectorState>({
  open: false,
  word: null,
  root: null,
});

export const wordsState = createState<string[]>([]);
