import { toArabic } from "arabic-digits";
import { css } from "@emotion/css";
import { FC, useEffect } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { useParams } from "react-router-dom";
import { useState } from "@hookstate/core";

import { Verse } from "quran-types";
import { siderState } from "../../components/sider/state";
import { inspectorState } from "../../components/inspector";
import { getVerses, verseState } from "./state";
import { Pagination } from "./pagination";
import { preferenceState } from "app-state";

interface ParagraphElt {
  root: string;
  word: string;
}

interface VerseNumberProps {
  nbr: number;
}

interface VerseListProps {
  verses: Verse[];
}

const style = css`
  margin-left: 4em;
  margin-top: 2em;
  width: 80%;
  text-align: center;
  direction: rtl;
`;

const selected = css`
  color: violet;
`;

const normal = css``;

const nbrStyle = css`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.7em;
  background-color: gainsboro;
  border-radius: 4px;
`;

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

const VerseList: FC<VerseListProps> = ({ verses }) => {
  return (
    <div className={style}>
      {verses.map((v: Verse, i) => (
        <Paragraph {...v} key={`Paragraph_${i}_${v.id}`} />
      ))}
    </div>
  );
};

const Paragraph: FC<Verse> = (v: Verse, { children }) => {
  const sv = v.content.split(" ");
  const sr = v.root_content.split(";");
  const state = useState(preferenceState);

  return (
    <span
      key={`p_${v.id}_${v.surah_id}`}
      style={{
        fontFamily: state.get().font,
        fontSize: `${state.get().fontSize}em`,
      }}
    >
      {sv.map((s, i) => (
        <ParagraphEl word={s} root={sr[i]} key={`ParagraphEl_${i}`} />
      ))}
      <VerseNumber nbr={v.id} />
    </span>
  );
};

const ParagraphEl: FC<ParagraphElt> = (p) => {
  const sState = useState(siderState);
  const iState = useState(inspectorState);

  const _onCLick = (w: string, r: string) => {
    sState.child.set("wordInspector");
    iState.merge({ word: w, root: r });
  };
  return (
    <span
      onClick={(e) => _onCLick(p.word, p.root)}
      className={p.word === iState.get().word ? selected : normal}
    >
      {` ${p.word} `}
    </span>
  );
};

const VerseNumber: FC<VerseNumberProps> = ({ nbr }) => {
  return <span className={nbrStyle}>{toArabic(nbr, true)}</span>;
};

export const Verses = () => {
  const p = useParams();
  const state = useState(verseState);
  const surah = parseInt(p.surah);
  const page = parseInt(p.page);

  useEffect(() => {
    state.set(getVerses({ surah, page }));
  }, [surah, page]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {}}>
      <Pagination></Pagination>
      {state.promised ? (
        <div className={style}>LOADING</div>
      ) : (
        <VerseList verses={state.get()} />
      )}
      <Pagination></Pagination>
    </ErrorBoundary>
  );
};
