import { Verse, VerseProps, VerseC, toVerse } from "quran-types";
import { createState } from "@hookstate/core";
import { myfetcher } from "../../app-state";

export const verseState = createState<Verse[]>([]);

export const getVerses = async (p: VerseProps): Promise<Verse[]> => {
  const url = `get_surah/${p.surah}/${p.page}`;
  const response = await myfetcher.get<VerseC[]>(url);
  return response.map((v: VerseC) => toVerse(v));
};
