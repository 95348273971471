import { css } from "@emotion/css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { useState } from "@hookstate/core";

import { About } from "../screens/about/view";
import { Settings } from "../screens/settings/view";

import { Verses } from "../screens/verses";

import { Sider } from "../components/sider";
import { siderState } from "../components/sider/state";
import { Container } from "../components/container";
import { Nav } from "../components/nav";
import { AnimateSharedLayout } from "framer-motion";

import quran from "../assets/icons/quran.svg";
import { Portal } from "components/portal";

const wrapper = css`
  display: flex;
  align-items: start;
  flex-direction: row;
  width: 100%;
`;

export const AppLayout = () => {
  const state = useState(siderState);

  const toggleView = (page: string) => {
    state.merge({ child: page });
  };
  return (
    <Router>
      <AnimateSharedLayout>
        <div className={wrapper}>
          <Nav>
            <div onClick={(e) => toggleView("surahList")}>
              <img src={quran} alt="surah list" width="36" />
            </div>
            <div>
              <Link to="/about">
                <img src={quran} alt="about" width="36" />
              </Link>
            </div>
            <div onClick={(e) => toggleView("settings")}>
              <img src={quran} alt="settings" width="36" />
            </div>
          </Nav>
          <Sider />
          <Container>
            <Routes>
              <Route path="/about" element={<About />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="surah/:surah/:page" element={<Verses />} />
            </Routes>
          </Container>
        </div>
        <Portal />
      </AnimateSharedLayout>
    </Router>
  );
};
