export const pages = [
  ["ا", 1],
  ["اادم", 2],
  ["اازر", 3],
  ["ابراهيم", 4],
  ["ابل", 5],
  ["ابنة", 6],
  ["اتقي", 7],
  ["اتي", 8],
  ["اتي", 9],
  ["اثر", 10],
  ["اثل", 11],
  ["اجج", 12],
  ["اجل", 13],
  ["اخذ", 14],
  ["اخذ", 15],
  ["اخر", 16],
  ["اخر", 17],
  ["اخو", 18],
  ["ادي", 19],
  ["اذ", 20],
  ["اذا", 21],
  ["اذن", 22],
  ["اذن", 23],
  ["اذي", 24],
  ["ارم", 25],
  ["ازف", 26],
  ["اسر", 27],
  ["اسف", 28],
  ["اشر", 29],
  ["افف", 30],
  ["افك", 31],
  ["اكل", 32],
  ["اكل", 33],
  ["ال", 34],
  ["الت", 35],
  ["الف", 36],
  ["الف", 37],
  ["اللذي", 38],
  ["الم", 39],
  ["الو", 40],
  ["الي", 41],
  ["الياس", 42],
  ["امت", 43],
  ["امر", 44],
  ["امر", 45],
  ["امر", 46],
  ["امم", 47],
  ["امم", 48],
  ["امما", 49],
  ["امن", 50],
  ["امن", 51],
  ["امن", 52],
  ["امو", 53],
  ["ان", 54],
  ["ان", 55],
  ["انث", 56],
  ["انس", 57],
  ["انم", 58],
  ["انني", 59],
  ["اني", 60],
  ["او", 61],
  ["او", 62],
  ["اود", 63],
  ["اول", 64],
  ["اوه", 65],
  ["ايد", 66],
  ["اين", 67],
  ["ايي", 68],
  ["ايي", 69],
  ["اييم", 70],
  ["ايين", 71],
  ["ب", 72],
  ["بابل", 73],
  ["باس", 74],
  ["بتل", 75],
  ["بحث", 76],
  ["بخس", 77],
  ["بدا", 78],
  ["بدر", 79],
  ["بدل", 80],
  ["بدن", 81],
  ["بدو", 82],
  ["برا", 83],
  ["براءة", 84],
  ["برج", 85],
  ["برر", 86],
  ["برز", 87],
  ["برق", 88],
  ["برك", 89],
  ["بسر", 90],
  ["بسط", 91],
  ["بشر", 92],
  ["بشري", 93],
  ["بصر", 94],
  ["بصيرة", 95],
  ["بضع", 96],
  ["بطل", 97],
  ["بطن", 98],
  ["بعث", 99],
  ["بعد", 100],
  ["بعد", 101],
  ["بعض", 102],
  ["بعل", 103],
  ["بغي", 104],
  ["بغي", 105],
  ["بقع", 106],
  ["بقي", 107],
  ["بكر", 108],
  ["بكي", 109],
  ["بلد", 110],
  ["بلغ", 111],
  ["بلغ", 112],
  ["بلو", 113],
  ["بلي", 114],
  ["بنو", 115],
  ["بني", 116],
  ["بهج", 117],
  ["بوأ", 118],
  ["بوب", 119],
  ["بيت", 120],
  ["بيد", 121],
  ["بيع", 122],
  ["بين", 123],
  ["بين", 124],
  ["بين", 125],
  ["بين", 126],
  ["تبب", 127],
  ["تبع", 128],
  ["تبع", 129],
  ["تبع", 130],
  ["ترب", 131],
  ["ترف", 132],
  ["ترك", 133],
  ["تقن", 134],
  ["تلو", 135],
  ["تمم", 136],
  ["توب", 137],
  ["توراة", 138],
  ["تيه", 139],
  ["ثبت", 140],
  ["ثبي", 141],
  ["ثري", 142],
  ["ثقل", 143],
  ["ثقل", 144],
  ["ثلث", 145],
  ["ثمر", 146],
  ["ثمن", 147],
  ["ثني", 148],
  ["ثوب", 149],
  ["ثوي", 150],
  ["ثيبات", 151],
  ["جبب", 152],
  ["جبل", 153],
  ["جبو", 154],
  ["جثو", 155],
  ["جدد", 156],
  ["جدل", 157],
  ["جذو", 158],
  ["جرز", 159],
  ["جرم", 160],
  ["جري", 161],
  ["جزي", 162],
  ["جزية", 163],
  ["جعل", 164],
  ["جعل", 165],
  ["جفن", 166],
  ["جلد", 167],
  ["جلو", 168],
  ["جمع", 169],
  ["جمعان", 170],
  ["جمل", 171],
  ["جنب", 172],
  ["جنب", 173],
  ["جند", 174],
  ["جنن", 175],
  ["جنن", 176],
  ["جهد", 177],
  ["جهر", 178],
  ["جهل", 179],
  ["جوب", 180],
  ["جور", 181],
  ["جور", 182],
  ["جوف", 183],
  ["جيء", 184],
  ["جيد", 185],
  ["حبب", 186],
  ["حبر", 187],
  ["حبك", 188],
  ["حتي", 189],
  ["حجج", 190],
  ["حجر", 191],
  ["حجر", 192],
  ["حجر", 193],
  ["حدد", 194],
  ["حذر", 195],
  ["حرب", 196],
  ["حرج", 197],
  ["حرر", 198],
  ["حرص", 199],
  ["حرف", 200],
  ["حرم", 201],
  ["حرم", 202],
  ["حرم", 203],
  ["حزب", 204],
  ["حسب", 205],
  ["حسب", 206],
  ["حسر", 207],
  ["حسس", 208],
  ["حسن", 209],
  ["حسن", 210],
  ["حشر", 211],
  ["حصب", 212],
  ["حصر", 213],
  ["حصل", 214],
  ["حصن", 215],
  ["حضر", 216],
  ["حضر", 217],
  ["حطط", 218],
  ["حظظ", 219],
  ["حفظ", 220],
  ["حفظ", 221],
  ["حفو", 222],
  ["حقف", 223],
  ["حقق", 224],
  ["حقق", 225],
  ["حكم", 226],
  ["حكم", 227],
  ["حكم", 228],
  ["حلق", 229],
  ["حلل", 230],
  ["حلل", 231],
  ["حلو", 232],
  ["حمد", 233],
  ["حمر", 234],
  ["حمل", 235],
  ["حمل", 236],
  ["حمم", 237],
  ["حنث", 238],
  ["حنك", 239],
  ["حوت", 240],
  ["حور", 241],
  ["حوش", 242],
  ["حوط", 243],
  ["حول", 244],
  ["حيد", 245],
  ["حيق", 246],
  ["حيي", 247],
  ["حيي", 248],
  ["حيي", 249],
  ["حيي", 250],
  ["خبت", 251],
  ["خبر", 252],
  ["خبل", 253],
  ["ختم", 254],
  ["خدع", 255],
  ["خرب", 256],
  ["خرج", 257],
  ["خرج", 258],
  ["خرر", 259],
  ["خرق", 260],
  ["خزو", 261],
  ["خسر", 262],
  ["خسر", 263],
  ["خشع", 264],
  ["خصص", 265],
  ["خصم", 266],
  ["خصم", 267],
  ["خضع", 268],
  ["خطأ", 269],
  ["خطب", 270],
  ["خطو", 271],
  ["خفف", 272],
  ["خفي", 273],
  ["خفي", 274],
  ["خلد", 275],
  ["خلص", 276],
  ["خلط", 277],
  ["خلف", 278],
  ["خلف", 279],
  ["خلف", 280],
  ["خلف", 281],
  ["خلق", 282],
  ["خلق", 283],
  ["خلو", 284],
  ["خمد", 285],
  ["خمد", 286],
  ["خنس", 287],
  ["خوض", 288],
  ["خوف", 289],
  ["خول", 290],
  ["خون", 291],
  ["خير", 292],
  ["خير", 293],
  ["خيم", 294],
  ["خيم", 295],
  ["دأب", 296],
  ["دبر", 297],
  ["دبر", 298],
  ["دحض", 299],
  ["دخل", 300],
  ["دخن", 301],
  ["درج", 302],
  ["درس", 303],
  ["درك", 304],
  ["دري", 305],
  ["دعع", 306],
  ["دعو", 307],
  ["دعو", 308],
  ["دفأ", 309],
  ["دفق", 310],
  ["دلل", 311],
  ["دلو", 312],
  ["دمغ", 313],
  ["دنو", 314],
  ["دهر", 315],
  ["دهن", 316],
  ["دور", 317],
  ["دول", 318],
  ["دون", 319],
  ["دين", 320],
  ["دينار", 321],
  ["ذا", 322],
  ["ذا", 323],
  ["ذبب", 324],
  ["ذبذب", 325],
  ["ذرع", 326],
  ["ذعن", 327],
  ["ذكر", 328],
  ["ذكر", 329],
  ["ذكر", 330],
  ["ذكر", 331],
  ["ذلل", 332],
  ["ذلل", 333],
  ["ذنب", 334],
  ["ذهب", 335],
  ["ذو", 336],
  ["ذوق", 337],
  ["ذيع", 338],
  ["رأي", 339],
  ["رأي", 340],
  ["رأي", 341],
  ["ربب", 342],
  ["ربص", 343],
  ["ربط", 344],
  ["ربو", 345],
  ["رتق", 346],
  ["رجج", 347],
  ["رجع", 348],
  ["رجع", 349],
  ["رجل", 350],
  ["رجم", 351],
  ["رجو", 352],
  ["رحل", 353],
  ["رحم", 354],
  ["ردأ", 355],
  ["ردد", 356],
  ["ردد", 357],
  ["ردف", 358],
  ["ردي", 359],
  ["رزق", 360],
  ["رسخ", 361],
  ["رسل", 362],
  ["رسل", 363],
  ["رسو", 364],
  ["رشد", 365],
  ["رصد", 366],
  ["رضع", 367],
  ["رضو", 368],
  ["رضو", 369],
  ["رعد", 370],
  ["رعي", 371],
  ["رغب", 372],
  ["رفث", 373],
  ["رفع", 374],
  ["رفق", 375],
  ["رقب", 376],
  ["رقم", 377],
  ["رقو", 378],
  ["ركد", 379],
  ["ركد", 380],
  ["ركم", 381],
  ["رمد", 382],
  ["رمم", 383],
  ["رهب", 384],
  ["رهط", 385],
  ["رهو", 386],
  ["روح", 387],
  ["رود", 388],
  ["روع", 389],
  ["ريب", 390],
  ["رين", 391],
  ["رين", 392],
  ["زبر", 393],
  ["زجر", 394],
  ["زحزح", 395],
  ["زرع", 396],
  ["زري", 397],
  ["زقم", 398],
  ["زكو", 399],
  ["زلزل", 400],
  ["زلف", 401],
  ["زلل", 402],
  ["زمهر", 403],
  ["زهد", 404],
  ["زوج", 405],
  ["زود", 406],
  ["زيت", 407],
  ["زيد", 408],
  ["زيل", 409],
  ["زين", 410],
  ["زين", 411],
  ["سأل", 412],
  ["سأل", 413],
  ["سبب", 414],
  ["سبح", 415],
  ["سبح", 416],
  ["سبع", 417],
  ["سبق", 418],
  ["سبل", 419],
  ["ستر", 420],
  ["سجد", 421],
  ["سجر", 422],
  ["سجن", 423],
  ["سحت", 424],
  ["سحت", 425],
  ["سخر", 426],
  ["سخط", 427],
  ["سدر", 428],
  ["سرب", 429],
  ["سرح", 430],
  ["سرر", 431],
  ["سرر", 432],
  ["سرف", 433],
  ["سرمد", 434],
  ["سطر", 435],
  ["سعد", 436],
  ["سعي", 437],
  ["سفر", 438],
  ["سفل", 439],
  ["سفه", 440],
  ["سقف", 441],
  ["سقي", 442],
  ["سكر", 443],
  ["سكن", 444],
  ["سكن", 445],
  ["سلخ", 446],
  ["سلط", 447],
  ["سلف", 448],
  ["سلل", 449],
  ["سلم", 450],
  ["سلم", 451],
  ["سلم", 452],
  ["سليمان", 453],
  ["سمر", 454],
  ["سمع", 455],
  ["سمع", 456],
  ["سمو", 457],
  ["سمو", 458],
  ["سنن", 459],
  ["سنو", 460],
  ["سهو", 461],
  ["سوأ", 462],
  ["سوأ", 463],
  ["سود", 464],
  ["سوط", 465],
  ["سوغ", 466],
  ["سوق", 467],
  ["سوم", 468],
  ["سوي", 469],
  ["سوي", 470],
  ["سير", 471],
  ["سيل", 472],
  ["سينين", 473],
  ["شبه", 474],
  ["شتت", 475],
  ["شحح", 476],
  ["شدد", 477],
  ["شرب", 478],
  ["شرب", 479],
  ["شرر", 480],
  ["شرق", 481],
  ["شرق", 482],
  ["شرك", 483],
  ["شطأ", 484],
  ["شطن", 485],
  ["شعب", 486],
  ["شعر", 487],
  ["شغل", 488],
  ["شفق", 489],
  ["شفي", 490],
  ["شقق", 491],
  ["شقو", 492],
  ["شكر", 493],
  ["شكل", 494],
  ["شمز", 495],
  ["شنأ", 496],
  ["شهد", 497],
  ["شهد", 498],
  ["شهد", 499],
  ["شهر", 500],
  ["شهو", 501],
  ["شوك", 502],
  ["شيأ", 503],
  ["شيد", 504],
  ["شيع", 505],
  ["صبأ", 506],
  ["صبح", 507],
  ["صبر", 508],
  ["صبر", 509],
  ["صبو", 510],
  ["صحب", 511],
  ["صحب", 512],
  ["صخر", 513],
  ["صدر", 514],
  ["صدع", 515],
  ["صدف", 516],
  ["صدق", 517],
  ["صدق", 518],
  ["صدق", 519],
  ["صرخ", 520],
  ["صرصر", 521],
  ["صرف", 522],
  ["صرم", 523],
  ["صعد", 524],
  ["صعق", 525],
  ["صغو", 526],
  ["صفر", 527],
  ["صفف", 528],
  ["صفو", 529],
  ["صلب", 530],
  ["صلح", 531],
  ["صلح", 532],
  ["صلو", 533],
  ["صلو", 534],
  ["صلي", 535],
  ["صمع", 536],
  ["صنع", 537],
  ["صنم", 538],
  ["صوب", 539],
  ["صور", 540],
  ["صوف", 541],
  ["صيح", 542],
  ["صير", 543],
  ["صيف", 544],
  ["ضبح", 545],
  ["ضحك", 546],
  ["ضرب", 547],
  ["ضرر", 548],
  ["ضرر", 549],
  ["ضرع", 550],
  ["ضعف", 551],
  ["ضعف", 552],
  ["ضغن", 553],
  ["ضلل", 554],
  ["ضلل", 555],
  ["ضنك", 556],
  ["ضير", 557],
  ["ضيق", 558],
  ["ضيق", 559],
  ["طبع", 560],
  ["طرد", 561],
  ["طرف", 562],
  ["طرو", 563],
  ["طعم", 564],
  ["طغو", 565],
  ["طغو", 566],
  ["طفف", 567],
  ["طلب", 568],
  ["طلع", 569],
  ["طلق", 570],
  ["طمس", 571],
  ["طمم", 572],
  ["طهر", 573],
  ["طهر", 574],
  ["طور", 575],
  ["طوع", 576],
  ["طوف", 577],
  ["طوق", 578],
  ["طول", 579],
  ["طيب", 580],
  ["طير", 581],
  ["طين", 582],
  ["ظلل", 583],
  ["ظلل", 584],
  ["ظلم", 585],
  ["ظلم", 586],
  ["ظلم", 587],
  ["ظنن", 588],
  ["ظهر", 589],
  ["ظهر", 590],
  ["ظهر", 591],
  ["ظهر", 592],
  ["عبد", 593],
  ["عبد", 594],
  ["عبس", 595],
  ["عتب", 596],
  ["عتب", 597],
  ["عثر", 598],
  ["عجب", 599],
  ["عجز", 600],
  ["عجل", 601],
  ["عجم", 602],
  ["عدد", 603],
  ["عدد", 604],
  ["عدن", 605],
  ["عدو", 606],
  ["عدو", 607],
  ["عذر", 608],
  ["عرب", 609],
  ["عرر", 610],
  ["عرض", 611],
  ["عرض", 612],
  ["عرف", 613],
  ["عرف", 614],
  ["عرو", 615],
  ["عزر", 616],
  ["عزز", 617],
  ["عزل", 618],
  ["عزو", 619],
  ["عسعس", 620],
  ["عشر", 621],
  ["عشر", 622],
  ["عصب", 623],
  ["عصف", 624],
  ["عصو", 625],
  ["عضض", 626],
  ["عطف", 627],
  ["عطو", 628],
  ["عفر", 629],
  ["عفو", 630],
  ["عقب", 631],
  ["عقب", 632],
  ["عقل", 633],
  ["علق", 634],
  ["علم", 635],
  ["علم", 636],
  ["علم", 637],
  ["علو", 638],
  ["علو", 639],
  ["علو", 640],
  ["علي", 641],
  ["عمر", 642],
  ["عمر", 643],
  ["عمق", 644],
  ["عمل", 645],
  ["عمي", 646],
  ["عن", 647],
  ["عند", 648],
  ["عنق", 649],
  ["عهد", 650],
  ["عود", 651],
  ["عود", 652],
  ["عور", 653],
  ["عوم", 654],
  ["عير", 655],
  ["عيس", 656],
  ["عين", 657],
  ["عيي", 658],
  ["غثو", 659],
  ["غدو", 660],
  ["غرب", 661],
  ["غرر", 662],
  ["غرق", 663],
  ["غرو", 664],
  ["غسق", 665],
  ["غشو", 666],
  ["غشو", 667],
  ["غضب", 668],
  ["غفر", 669],
  ["غفر", 670],
  ["غلب", 671],
  ["غلظ", 672],
  ["غلل", 673],
  ["غلو", 674],
  ["غمض", 675],
  ["غني", 676],
  ["غني", 677],
  ["غور", 678],
  ["غوي", 679],
  ["غيب", 680],
  ["غيث", 681],
  ["غير", 682],
  ["غيظ", 683],
  ["ف", 684],
  ["ف", 685],
  ["ف", 686],
  ["ف", 687],
  ["فأد", 688],
  ["فتح", 689],
  ["فتح", 690],
  ["فتق", 691],
  ["فتن", 692],
  ["فتي", 693],
  ["فجج", 694],
  ["فجر", 695],
  ["فحش", 696],
  ["فدي", 697],
  ["فرج", 698],
  ["فرد", 699],
  ["فرش", 700],
  ["فرض", 701],
  ["فرط", 702],
  ["فرع", 703],
  ["فرق", 704],
  ["فرق", 705],
  ["فرق", 706],
  ["فري", 707],
  ["فسح", 708],
  ["فسد", 709],
  ["فسق", 710],
  ["فسق", 711],
  ["فصل", 712],
  ["فصل", 713],
  ["فضض", 714],
  ["فضل", 715],
  ["فطر", 716],
  ["فعل", 717],
  ["فقر", 718],
  ["فقه", 719],
  ["فكه", 720],
  ["فلق", 721],
  ["فلن", 722],
  ["فوت", 723],
  ["فور", 724],
  ["فوض", 725],
  ["فوم", 726],
  ["في", 727],
  ["فيض", 728],
  ["فيل", 729],
  ["قارون", 730],
  ["قبس", 731],
  ["قبض", 732],
  ["قبل", 733],
  ["قبل", 734],
  ["قتر", 735],
  ["قتل", 736],
  ["قتل", 737],
  ["قد", 738],
  ["قدد", 739],
  ["قدر", 740],
  ["قدر", 741],
  ["قدس", 742],
  ["قدم", 743],
  ["قدم", 744],
  ["قرأ", 745],
  ["قرأ", 746],
  ["قرب", 747],
  ["قرب", 748],
  ["قرد", 749],
  ["قرر", 750],
  ["قرر", 751],
  ["قرض", 752],
  ["قرف", 753],
  ["قرن", 754],
  ["قسس", 755],
  ["قسطاس", 756],
  ["قسم", 757],
  ["قشعر", 758],
  ["قصر", 759],
  ["قصص", 760],
  ["قصم", 761],
  ["قضي", 762],
  ["قضي", 763],
  ["قضي", 764],
  ["قطع", 765],
  ["قطع", 766],
  ["قطمر", 767],
  ["قعد", 768],
  ["قفل", 769],
  ["قلب", 770],
  ["قلب", 771],
  ["قلد", 772],
  ["قلل", 773],
  ["قلو", 774],
  ["قمص", 775],
  ["قنت", 776],
  ["قنطر", 777],
  ["قهر", 778],
  ["قوس", 779],
  ["قول", 780],
  ["قول", 781],
  ["قوم", 782],
  ["قوم", 783],
  ["قوم", 784],
  ["قوم", 785],
  ["قوم", 786],
  ["قوي", 787],
  ["قيل", 788],
  ["ك", 789],
  ["كافور", 790],
  ["كبد", 791],
  ["كبر", 792],
  ["كبر", 793],
  ["كبر", 794],
  ["كتب", 795],
  ["كتب", 796],
  ["كتب", 797],
  ["كثر", 798],
  ["كثر", 799],
  ["كذب", 800],
  ["كذب", 801],
  ["كرر", 802],
  ["كرم", 803],
  ["كره", 804],
  ["كسب", 805],
  ["كسو", 806],
  ["كشف", 807],
  ["كعب", 808],
  ["كفر", 809],
  ["كفر", 810],
  ["كفر", 811],
  ["كفف", 812],
  ["كفل", 813],
  ["كفي", 814],
  ["كلح", 815],
  ["كلل", 816],
  ["كللا", 817],
  ["كلم", 818],
  ["كم", 819],
  ["كند", 820],
  ["كنن", 821],
  ["كهل", 822],
  ["كود", 823],
  ["كون", 824],
  ["كون", 825],
  ["كيد", 826],
  ["كيل", 827],
  ["كين", 828],
  ["ل", 829],
  ["ل", 830],
  ["ل", 831],
  ["لا", 832],
  ["لات", 833],
  ["لبث", 834],
  ["لبس", 835],
  ["لجج", 836],
  ["لحق", 837],
  ["لدد", 838],
  ["لذذ", 839],
  ["لسن", 840],
  ["لعب", 841],
  ["لعن", 842],
  ["لغو", 843],
  ["لفح", 844],
  ["لقب", 845],
  ["لقي", 846],
  ["لقي", 847],
  ["لقي", 848],
  ["لقي", 849],
  ["لمز", 850],
  ["لمم", 851],
  ["لن", 852],
  ["لهو", 853],
  ["لو", 854],
  ["لوح", 855],
  ["لولا", 856],
  ["لوم", 857],
  ["لوي", 858],
  ["ليل", 859],
  ["لين", 860],
  ["لين", 861],
  ["ما", 862],
  ["ما", 863],
  ["مأي", 864],
  ["متع", 865],
  ["متع", 866],
  ["مثل", 867],
  ["مثل", 868],
  ["محص", 869],
  ["محن", 870],
  ["مدد", 871],
  ["مدد", 872],
  ["مدن", 873],
  ["مرج", 874],
  ["مرد", 875],
  ["مرر", 876],
  ["مرو", 877],
  ["مري", 878],
  ["مزج", 879],
  ["مسح", 880],
  ["مسح", 881],
  ["مسس", 882],
  ["مسك", 883],
  ["مسو", 884],
  ["مشي", 885],
  ["مضي", 886],
  ["مع", 887],
  ["معن", 888],
  ["مكث", 889],
  ["مكك", 890],
  ["ملأ", 891],
  ["ملح", 892],
  ["ملك", 893],
  ["ملك", 894],
  ["ملل", 895],
  ["من", 896],
  ["من", 897],
  ["منع", 898],
  ["منن", 899],
  ["منو", 900],
  ["منو", 901],
  ["مهد", 902],
  ["موت", 903],
  ["موت", 904],
  ["مور", 905],
  ["مول", 906],
  ["مير", 907],
  ["ميل", 908],
  ["ميل", 909],
  ["ن", 910],
  ["ن", 911],
  ["ن", 912],
  ["نبأ", 913],
  ["نبأ", 914],
  ["نبت", 915],
  ["نبذ", 916],
  ["نبع", 917],
  ["نجس", 918],
  ["نجو", 919],
  ["نجو", 920],
  ["نحر", 921],
  ["نحل", 922],
  ["ندم", 923],
  ["ندو", 924],
  ["نذر", 925],
  ["نزع", 926],
  ["نزع", 927],
  ["نزغ", 928],
  ["نزل", 929],
  ["نزل", 930],
  ["نزل", 931],
  ["نسأ", 932],
  ["نسخ", 933],
  ["نسك", 934],
  ["نسو", 935],
  ["نسي", 936],
  ["نشأ", 937],
  ["نشر", 938],
  ["نشر", 939],
  ["نشط", 940],
  ["نصت", 941],
  ["نصر", 942],
  ["نصر", 943],
  ["نصف", 944],
  ["نضد", 945],
  ["نطق", 946],
  ["نظر", 947],
  ["نظر", 948],
  ["نعل", 949],
  ["نعم", 950],
  ["نفث", 951],
  ["نفد", 952],
  ["نفر", 953],
  ["نفس", 954],
  ["نفع", 955],
  ["نفق", 956],
  ["نفو", 957],
  ["نقذ", 958],
  ["نقص", 959],
  ["نقم", 960],
  ["نكث", 961],
  ["نكد", 962],
  ["نكر", 963],
  ["نكر", 964],
  ["نكل", 965],
  ["نمم", 966],
  ["نهو", 967],
  ["نهو", 968],
  ["نوح", 969],
  ["نور", 970],
  ["نوش", 971],
  ["نوم", 972],
  ["نيل", 973],
  ["نيل", 974],
  ["ه", 975],
  ["ها", 976],
  ["هاروت", 977],
  ["هبط", 978],
  ["هجر", 979],
  ["هدد", 980],
  ["هدي", 981],
  ["هدي", 982],
  ["هدي", 983],
  ["هزأ", 984],
  ["هزم", 985],
  ["هضم", 986],
  ["هلك", 987],
  ["هلل", 988],
  ["هم", 989],
  ["همد", 990],
  ["همس", 991],
  ["هنا", 992],
  ["هنا", 993],
  ["هود", 994],
  ["هون", 995],
  ["هوي", 996],
  ["هي", 997],
  ["هيت", 998],
  ["هيم", 999],
  ["هيمن", 1000],
  ["و", 1001],
  ["و", 1002],
  ["و", 1003],
  ["و", 1004],
  ["و", 1005],
  ["و", 1006],
  ["و", 1007],
  ["وبر", 1008],
  ["وتد", 1009],
  ["وثق", 1010],
  ["وجب", 1011],
  ["وجس", 1012],
  ["وجه", 1013],
  ["وجه", 1014],
  ["وحش", 1015],
  ["ودد", 1016],
  ["ودع", 1017],
  ["وذر", 1018],
  ["ورث", 1019],
  ["ورد", 1020],
  ["وري", 1021],
  ["وزر", 1022],
  ["وزع", 1023],
  ["وسط", 1024],
  ["وسع", 1025],
  ["وسن", 1026],
  ["وصد", 1027],
  ["وصل", 1028],
  ["وصي", 1029],
  ["وضع", 1030],
  ["وطأ", 1031],
  ["وعد", 1032],
  ["وعد", 1033],
  ["وعظ", 1034],
  ["وفر", 1035],
  ["وفي", 1036],
  ["وفي", 1037],
  ["وقت", 1038],
  ["وقر", 1039],
  ["وقع", 1040],
  ["وقف", 1041],
  ["وقي", 1042],
  ["وكأ", 1043],
  ["وكل", 1044],
  ["ولد", 1045],
  ["ولد", 1046],
  ["ولي", 1047],
  ["ولي", 1048],
  ["وني", 1049],
  ["وهي", 1050],
  ["ويل", 1051],
  ["يا", 1052],
  ["ياقوت", 1053],
  ["يدي", 1054],
  ["يسر", 1055],
  ["يسر", 1056],
  ["يقطين", 1057],
  ["يم", 1058],
  ["يمن", 1059],
  ["يوم", 1060],
  ["يوم", 1061],
  ["يوم", 1062],
  ["يونس", 1063],
  ["يونس", 1064],
];
