export class fetcher {
  baseUrl: string;
  cacheName: string;

  constructor(baseUrl: string, cacheName: string = "myAppCache") {
    this.baseUrl = baseUrl;
    this.cacheName = cacheName;
  }

  /**
   * http get request with retry and cache
   * @param urlPath
   * @param skipCache
   */
  async get<T>(urlPath: string, skipCache: boolean = false) {
    const url = new URL(urlPath, this.baseUrl);
    const newReq = new Request(url.href);
    const response = await this.withCache(newReq, skipCache);
    return (await response.json()) as T;
  }

  /**
   * http get request with retry and cache
   * @param urlPath
   * @param skipCache
   */
  post = async (urlPath: string, body: any, bust?: string[]) => {
    const url = new URL(urlPath, this.baseUrl);

    await fetch(url.href, {
      method: "POST",
      body: body,
    });

    // cache busting
    if (bust !== undefined) await this.bustCache(bust);
  };

  /**
   * http get request with retry and cache
   * @param urlPath
   * @param skipCache
   */
  del = async () => {};

  /**
   * search in browser cache for matching request
   * @param urlPath
   * @param skipCache
   */
  private withCache = async (req: Request, skipCache: boolean) => {
    if (skipCache === true) {
      return await fetch(req);
    }
    const appCache = await caches.open(this.cacheName);
    const cachedResponse = await appCache.match(req);

    if (cachedResponse === undefined) {
      const r = await fetch(req);
      await appCache.put(req, r.clone());
      return r;
    }
    return cachedResponse;
  };

  /**
   * delete cache entries
   * @param urlPath
   * @param skipCache
   */
  private bustCache = async (busted: string[]) => {
    const appCache = await caches.open(this.cacheName);

    for (let i = 0; i < busted.length; i++) {
      let url = new URL(busted[i], this.baseUrl);
      await appCache.delete(url.href);
    }
  };
}
